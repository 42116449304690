

export const Home = () => {
  return (
    <div className="flex justify-between items-center px-8 bg-white h-20"> 
      <h1 className="font-bold text-3xl text-black"> moult </h1>
      <button className="bg-blue-700 hover:bg-blue-600 text-white font-semibold text-lg px-6 py-2 rounded-md shadow-2xl hover:shadow-inner"> Login </button>
    </div>
  );
}
