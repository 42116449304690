import { Routes } from "./routes";

function App() {
  return (
    <div id="app">
      <Routes />
    </div>
  );
}

export default App;
